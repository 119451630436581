// External Dependencies
import React, { useEffect } from 'react';

// Internal Dependencies
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Local Variables
const tmeaBoothUrl = 'https://2021convention.tmea.org/organizations/ni3haN7Z8C9x5JCi2';

// Component Definition
const TMEAPage = () => {
  useEffect(() => {
    window.location.href = tmeaBoothUrl;
  }, []);

  return (
    <Layout hideFooter>
      <Meta title="TMEA" />

      <header id="header">
        <h2>
          Redirecting you to our TMEA virtual booth...
        </h2>
      </header>
    </Layout>
  );
};

export default TMEAPage;
